import React, { useContext, useMemo } from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import { ReactComponent as Logo } from "../assets/logo-full.svg"
import PageTransition from "gatsby-plugin-page-transitions"
import { DomainContext } from "../context/DomainContext"
import GatsbyImageWithIEPolyfill from "gatsby-image/withIEPolyfill"

const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  background: ${props => props.theme.primaryColor};

  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img,
  > div {
    color: white;
    width: 50vw;
    height: 50vh;
    path {
      fill: white;
    }
  }

  img {
    object-fit: contain;
  }
`

export default ({ data: { indexPages } }) => {
  const { loadingScreenImage } = useContext(DomainContext)
  const Image = useMemo(() => {
    if (!loadingScreenImage) return <Logo />
    if (loadingScreenImage.fluid) {
      return (
        <GatsbyImageWithIEPolyfill
          fluid={loadingScreenImage.fluid}
          objectFit={"contain"}
          loading={"eager"}
        />
      )
    }
    return <img src={loadingScreenImage.url} />
  }, [loadingScreenImage])
  React.useEffect(() => {
    let language

    const lang =
      navigator && navigator.language && navigator.language.split("-")[0]

    if (indexPages.edges.find(({ node }) => node.locale === lang))
      language = lang
    else {
      language = "nl"
    }

    setTimeout(() => navigate(`/${language}`, { replace: true }), 1500)
  })
  return (
    <PageTransition>
      <Loader>{Image}</Loader>
    </PageTransition>
  )
}

export const query = graphql`
  {
    indexPages: allDatoCmsPage(filter: { slug: { in: ["", "home", null] } }) {
      edges {
        node {
          id
          name
          locale
          slug
        }
      }
    }
  }
`
